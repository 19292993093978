@tailwind components;
@tailwind base;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

.fadeIn {
  animation: 0.2s fadeIn forwards;
}

.fadeOut {
  animation: 0.2s fadeOut forwards;
}

.dash {
  stroke-dasharray: 10;
}

@keyframes fadeIn {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: -100;
  }
}

@keyframes scaleinout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.intercom-launcher {
  display: none;
}

[contenteditable='true']:focus {
  outline: none;
}

*:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.h-resize {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.custom-props-list-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}
